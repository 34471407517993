import React from 'react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import {MediaSectionV2} from '@HealthShareAU/hs-component-library';

export default
@observer
class GalleriesSection extends React.Component {
    static contextType = PracticeStoreContextV2;

    constructor(props) {
        super(props);
    }

    render() {
        const practice = toJS(this.context.practice);

        const {
            rootStore: {
                paramStore: {isClient},
            },
        } = this.context;
        const {images, videos} = practice;

        let props = {}

        if(images?.length>0){
            props.imageList = images
        }

        if(videos?.length>0){
            props.videoList = videos
        }
        return (
            <>
                {!isClient && (
                    <MediaSectionV2 {...props}/>
                )}
            </>
        );
    }
}
