import React from 'react';

import {HICAPS, TOOLTIP_HICAPS_TEXT} from 'core/constants.js';
import {PillV2} from '@HealthShareAU/hs-component-library';

const HicapsPill = ({colorTheme}) => {
    return (
        <PillV2
            colorTheme={colorTheme}
            dataTestId="hicaps-pill"
            key="hicaps"
            text={HICAPS}
            tooltip={TOOLTIP_HICAPS_TEXT}
        />
    );
};
export default HicapsPill;
