import React from 'react';
import {observer} from 'mobx-react';

import autobind from 'common/decorators/autobind.js';
import {
    DESKTOP_MIN_WIDTH,
    LOCATION_SECTION,
    HOSPITAL_AFFILIATIONS_CARD_LINK,
    HOSPITAL_CARD,
    HOSPITAL_CONTACT_TYPE,
    ADDRESS_LINK,
    ORIENTATION_TYPE_LANDSCAPE,
    ORIENTATION_TYPE_PORTRAIT,
} from 'core/constants.js';

import Ad from 'core/components/Ad.js';
import MapV2 from 'core/components/v2/MapV2.js';
import events from 'events.js';
import {analyticsConfig} from 'analytics.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';

import {
    LocationSectionV2,
    HospitalAffiliationsV2,
} from '@HealthShareAU/hs-component-library';

const {locationSection: gaConfig} = analyticsConfig.pages.practice;

export default
@observer
class LocationSection extends React.Component {
    static contextType = PracticeStoreContextV2;
    state = {
        orientation: 'portrait',
    };

    get locationCount() {
        const {userId, practice} = this.context;
        if (!!userId) {
            return practice.locations?.length;
        }
        if (!!practice?.amountOfLocations) {
            return practice.amountOfLocations;
        }
        return 0;
    }

    get branchesLinkConfigs() {
        const {practice} = this.context;
        if (this.locationCount <= 1) {
            return {};
        }
        return {
            href: practice?.practiceGroupLink,
            linkText: `See all ${this.locationCount} branches`,
            gaObjectType: gaConfig.gaObjectType,
            gaSelector: gaConfig.practiceGroupLink,
        };
    }

    get selectorGAConfig() {
        return {
            gaObjectType: gaConfig.gaObjectType,
            gaSelector: gaConfig.selector,
        };
    }

    @autobind
    setActiveLocationDisplay(newIdx) {
        const {practice} = this.context;
        if (!practice.locations?.length) {
            return null;
        }
        this.context.updateStore({locationSectionActiveItemIdx: newIdx});
    }

    renderHospitalAffiliations() {
        const {
            showCallNow,
            practice: {hospitals},
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this.context;

        if (!hospitals || hospitals.length === 0) {
            return null;
        }

        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;

        const title = {
            heading: 'Hospital affiliations',
            subHeading:
                'This practitioner may have admitting, consulting, and practising privileges at these hospitals.',
        };

        const capitalizeFirstLetter = (str) => {
            if (typeof str !== 'string' || str.length === 0) {
                return str;
            }
            return str.charAt(0).toUpperCase() + str.slice(1);
        };

        const processHospital = (hospital) => {
            const {
                avatar,
                id,
                group,
                type,
                address,
                phone,
                url,
                displayName,
            } = hospital;
            const hospitalGroupName = group?.id ? group.name : '';
            const subheader = `${capitalizeFirstLetter(type)} ${
                hospitalGroupName ? `• ${hospitalGroupName}` : ' Hospital'
            }`;

            return {
                avatar: {
                    src: avatar,
                    icon: 'building',
                },
                header: displayName,
                subheader,
                addressChip: {
                    addressText: address,
                    gaObjectType: HOSPITAL_CARD,
                    gaSelector: ADDRESS_LINK,
                },
                phoneChip: {
                    phone,
                },
                gaObjectType: HOSPITAL_CARD,
                gaSelector: HOSPITAL_AFFILIATIONS_CARD_LINK,
                dataTestId: HOSPITAL_CARD,
                onPhoneClick: () =>
                    showCallNow({
                        id,
                        address,
                        name: displayName,
                        phoneNumbers: [
                            {
                                formattedNumber: phone,
                            },
                        ],
                        type: HOSPITAL_CONTACT_TYPE,
                    }),
                href: url,
            };
        };
        let items = [];
        const hospitalsKeys = Object.keys(hospitals[0]);
        if (
            hasSkin &&
            hospitalsKeys.includes('hfr') &&
            hospitalsKeys.includes('hospitals')
        ) {
            // With HF and with skin
            // and hospital affiliations have related HFRs
            items = hospitals.flatMap((network) => {
                const {displayName, tooltip, id} = network?.hfr;
                return network.hospitals.map((hospital) => ({
                    ...processHospital(hospital),
                    extraPill: {
                        isGreyTheme: !id,
                        label: displayName,
                        tooltip: tooltip,
                    },
                }));
            });
        } else {
            // No HF, no skin
            // With HF and skin, but no HFRs for hospital affiliations
            items = hospitals.map(processHospital);
        }

        return (
            <HospitalAffiliationsV2
                items={items}
                title={title}
                colorTheme={colorTheme}
            />
        );
    }

    componentDidMount() {
        this.onWindowResize();
        events.listen(window, ['resize', 'orientationchange'], () => {
            this.onWindowResize();
        });
    }

    componentWillUnmount() {
        events.unlisten(window, ['resize', 'orientationchange'], () => {
            this.onWindowResize();
        });
    }

    getDeviceOrientation() {
        let orientationType = ORIENTATION_TYPE_PORTRAIT;
        if (window.screen?.orientation) {
            orientationType = window.screen?.orientation.type.includes(
                ORIENTATION_TYPE_PORTRAIT,
            )
                ? ORIENTATION_TYPE_PORTRAIT
                : ORIENTATION_TYPE_LANDSCAPE;
        } else {
            // for iOS only
            orientationType =
                window.innerHeight > window.innerWidth
                    ? ORIENTATION_TYPE_PORTRAIT
                    : ORIENTATION_TYPE_LANDSCAPE;
        }

        return orientationType;
    }

    @autobind
    onWindowResize() {
        this.setState({
            orientation: this.getDeviceOrientation(),
        });
    }

    render() {
        const {
            adData,
            chips,
            collapseSections,
            collapseSectionsHeader,
            locationSectionActiveItem,
            pills,
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
            userId,
        } = this.context;
        const {orientation} = this.state;

        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;

        const screenWidth = document.documentElement.clientWidth;

        const customClassName = adData
            ? 'profile-location-section-v2-with-ad'
            : '';

        const {latitude, longitude} = locationSectionActiveItem;
        const validAddress = latitude !== 0 && longitude !== 0;
        const partialMatch = !validAddress ? 'partial-match' : '';
        const adsClass = !adData ? 'no-ads' : '';
        const viewClass =
            screenWidth <= DESKTOP_MIN_WIDTH ? 'mobile-view' : 'desktop-view';
        const orientationClass =
            orientation === 'portrait' ? 'portrait' : 'landscape';

        return (
            <div
                className={`location-container-v2 ${partialMatch} ${adsClass} ${viewClass} ${orientationClass}`
                    .replace(/\s+/g, ' ')
                    .trim()}
            >
                <LocationSectionV2
                    branchesLinkConfigs={this.branchesLinkConfigs}
                    chips={chips}
                    collapseSections={collapseSections}
                    colorTheme={colorTheme}
                    customClass={customClassName}
                    dataTestId={LOCATION_SECTION}
                    displayLocationsCount={this.locationCount}
                    gaObjectType={LOCATION_SECTION}
                    gaSelector="selector"
                    infoListHeader={collapseSectionsHeader}
                    pills={pills}
                    sectionHeader={'Practice location'}
                    selectorGAConfig={this.selectorGAConfig}
                    setActiveLocationDisplay={this.setActiveLocationDisplay}
                    showButtonSlider={!!userId}
                >
                    {validAddress && (
                        <MapV2
                            center={{
                                latitude,
                                longitude,
                            }}
                            markerColor={colorTheme}
                            gaSelector={gaConfig.map}
                            gaObjectType={gaConfig.gaObjectType}
                        />
                    )}
                    {adData && (
                        <Ad
                            {...adData}
                            adUnit="sidebar"
                            customClass={'ad-v2-desktop'}
                            slotNumber={2}
                        />
                    )}
                </LocationSectionV2>
                {adData && (
                    <Ad
                        {...adData}
                        adUnit="sidebar"
                        customClass={'ad-v2-mobile'}
                        slotNumber={3}
                    />
                )}
                {this.renderHospitalAffiliations()}
            </div>
        );
    }
}
