import React from 'react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';

import autobind from 'common/decorators/autobind.js';

import {PRACTITIONERS_SECTION} from 'core/constants.js';

import HealthFundPickerV2 from 'core/components/v2/HealthFundPicker.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import PractitionerList from 'practices/components/v2/PractitionerListV2.js';
import PracticeList from 'practices/components/v2/PracticeListV2.js';

import {SectionV2} from '@HealthShareAU/hs-component-library';

export default
@observer
class BookingSection extends React.Component {
    static contextType = PracticeStoreContextV2;

    constructor(props) {
        super(props);
    }

    @autobind
    async HFselector(e) {
        const {selectHealthFundHandler} = this.context;
        selectHealthFundHandler(e);
    }

    render() {
        const practice = toJS(this.context.practice);
        const {
            rootStore: {
                healthFundStore: {healthFund},
                paramStore: {isHfWidget},
            },
            hasFees,
        } = this.context;
        let healthFundBrand = null;
        if (isHfWidget && healthFund?.id) {
            healthFundBrand = {
                color: `#${healthFund.colorBrand}`,
            };
        }
        return (
            <>
                {/* We dont show HF selector for user-profile booking section */}
                {!hasFees && (
                    <HealthFundPickerV2
                        gaObjectType={PRACTITIONERS_SECTION}
                        handleSelectHealthFund={(e) => this.HFselector(e)}
                        initialOptionText={
                            "I don't have private health insurance"
                        }
                        isHidden={isHfWidget}
                        stopAutoScroll={true}
                    />
                )}
                <SectionV2
                    colorTheme={healthFundBrand}
                    gaSelector={PRACTITIONERS_SECTION}
                    header={this.context.bookingsTab.title}
                >
                    {practice.practitioners?.length > 0 && (
                        <PractitionerList />
                    )}
                    {practice.locations?.length > 0 && <PracticeList />}
                </SectionV2>
            </>
        );
    }
}
