import PropTypes from 'prop-types';
import React from 'react';
import analytics from 'analytics.js';

import autobind from 'common/decorators/autobind.js';
import {HealthFundStoreContext} from 'core/stores/RootStore.js';
import {observer} from 'mobx-react';
import {HEALTH_FUND_SELECTOR, SELECT_HEALTH_FUND_BTN} from 'core/constants';

import {NotificationV2, DropdownV2} from '@HealthShareAU/hs-component-library';

export default
@observer
class HealthFundPicker extends React.Component {
    static contextType = HealthFundStoreContext;

    static propTypes = {
        containerClassName: PropTypes.string,
        customClass: PropTypes.string,
        handleMouseDown: PropTypes.func,
        handleSelectHealthFund: PropTypes.func,
        handleToggle: PropTypes.func,
        gaObjectType: PropTypes.string,
        initialOptionText: PropTypes.string,
        isHidden: PropTypes.bool,
        isMedical: PropTypes.bool,
        showSelector: PropTypes.bool,
        stopAutoScroll: PropTypes.bool,
        profileId: PropTypes.string,
    };

    static defaultProps = {
        containerClassName: '',
        customClass: '',
        showSelector: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    @autobind
    healthFundHandler(hf) {
        const {handleSelectHealthFund, profileId} = this.props;
        const {getCurrentURLPathList} = this.context;
        const pageType = getCurrentURLPathList().includes('profile');
        this.setState((state) => ({
            isOpen: !state.isOpen,
        }));
        handleSelectHealthFund(hf);

        if (pageType) {
            analytics.track('healthFundSelect', {
                practitionerId: profileId,
                hsHealthFundId: hf?.id,
            });
        }
    }

    @autobind
    handleMouseDown(event) {
        const {handleMouseDown} = this.props;
        if (handleMouseDown) {
            handleMouseDown(event);
        }
    }

    @autobind
    handleToggle(status) {
        this.setState({
            isOpen: status,
        });
        const {handleToggle} = this.props;
        if (handleToggle) {
            handleToggle(status);
        }
    }

    healthFundList() {
        const {initialOptionText} = this.props;
        const {healthFunds} = this.context;
        const options = [
            {
                name: "I don't have private health insurance",
                hasDivider: true,
                isDefaultOption: true,
                label: 'Select health fund',
            },
        ];
        healthFunds?.forEach((hf) =>
            options.push({
                id: hf?.id,
                name: hf.name,
                imageSource: hf?.avatar,
            }),
        );
        return options;
    }

    get pickerTitle() {
        const {isMedical} = this.props;
        const {
            rootStore: {
                paramStore: {isReferrals},
            },
        } = this.context;

        if (isReferrals) {
            return isMedical
                ? 'Your patient’s medical costs may be reduced when the practitioner uses a health fund gap scheme for hospital treatment.'
                : 'Your patient’s treatment costs may be reduced when the practitioner participates in a health fund member arrangement.';
        }
        return 'Your treatment costs may be reduced when the practitioner participates in a health fund member arrangement.';
    }

    get selectedHF() {
        const {healthFund} = this.context;

        if (this.healthFundList().length) {
            return this.healthFundList().find(
                (hf) => hf?.id === healthFund?.id,
            );
        }

        return {};
    }

    render() {
        const {
            containerClassName,
            customClass,
            gaObjectType,
            isHidden,
            showSelector,
        } = this.props;

        if (isHidden) {
            return null;
        }
        return (
            <NotificationV2
                customClass={`${containerClassName}`.trim()}
                type="info"
                text={this.pickerTitle}
            >
                {showSelector && (
                    <DropdownV2
                        customClass={customClass}
                        dataTestId={HEALTH_FUND_SELECTOR}
                        gaSelector={SELECT_HEALTH_FUND_BTN}
                        gaObjectType={gaObjectType}
                        options={this.healthFundList()}
                        onChange={(hf) => this.healthFundHandler(hf)}
                        value={this.selectedHF}
                    />
                )}
            </NotificationV2>
        );
    }
}
