import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';

import {
    HeaderTitleV2,
    LinkV2,
    SectionContentWrapperV2,
} from '@HealthShareAU/hs-component-library';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import {trackOpenPracticeLocationFile} from 'practices/services/internalEventTracking.js';

import {RELATED_INFO_SECTION, REGISTRATION_NUMBERS} from 'core/constants';

export default class RelatedInfoV2 extends React.Component {
    static contextType = PracticeStoreContextV2;

    static propTypes = {
        factsheets: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                sponsor: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        files: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        registrationNumbers: PropTypes.string,
        name: PropTypes.string.isRequired,
    };

    renderFactsheet(factsheet) {
        const {id, name, sponsor, url} = factsheet;
        const contents = (
            <div className="factsheet-text">
                <div className="factsheet-name">{name}</div>
                <div className="factsheet-source">{sponsor}</div>
            </div>
        );
        return (
            <li key={id} className="related-info-item">
                <LinkV2
                    href={url}
                    iconLeft="share"
                    text={contents}
                    gaObjectType={RELATED_INFO_SECTION}
                    gaSelector="factsheets"
                    dataTestId="factsheets"
                />
            </li>
        );
    }

    renderRelatedFactsheets() {
        const {factsheets, name} = this.props;
        const {
            rootStore: {
                paramStore: {isClient},
            },
            practice: {enhanced, premiumPartner},
        } = this.context;
        const factsheetsBlurb = `
            Concise and verified healthcare information related
            to ${name}'s field of practice. These cover conditions,
            treatments, and health products in an easy-to-read,
            high-quality format.`;
        if (!isClient && !enhanced && !premiumPartner && factsheets?.length) {
            return (
                <div className="factsheets-wrapper">
                    <HeaderTitleV2
                        headerType="submain"
                        heading="Related Factsheets"
                    />
                    <p className="factsheets-blurb">{factsheetsBlurb}</p>
                    <ul className="factsheets">
                        {factsheets.map((factsheet) =>
                            this.renderFactsheet(factsheet),
                        )}
                    </ul>
                </div>
            );
        }
        return null;
    }

    renderPracticeFile(file) {
        return (
            <li key={file.id} className="related-info-item">
                <LinkV2
                    id={file.id}
                    name={file.name}
                    text={file.name}
                    href={file.url}
                    iconLeft="fileAttachment"
                    gaObjectType={RELATED_INFO_SECTION}
                    gaSelector="documents"
                    dataTestId="documents"
                    onClick={() => trackOpenPracticeLocationFile(file.id)}
                ></LinkV2>
            </li>
        );
    }

    renderDocuments() {
        const {files} = this.props;
        if (files?.length > 0) {
            return (
                <div className="documents-wrapper">
                    <HeaderTitleV2 headerType="submain" heading="Documents" />
                    <ul className="documents">
                        {files.map((file) => this.renderPracticeFile(file))}
                    </ul>
                </div>
            );
        }
        return null;
    }

    renderRegistrationNumbers() {
        const {registrationNumbers} = this.props;
        return (
            registrationNumbers && (
                <div
                    className="registration-numbers-wrapper"
                    data-ga-object-type={RELATED_INFO_SECTION}
                    data-ga-selector={REGISTRATION_NUMBERS}
                    data-testid={REGISTRATION_NUMBERS}
                >
                    <HeaderTitleV2
                        headerType="submain"
                        heading="Registration numbers and professional memberships"
                    />
                    <div
                        className="registration-numbers"
                        dangerouslySetInnerHTML={{__html: registrationNumbers}}
                    />
                </div>
            )
        );
    }

    render() {
        const {factsheets, files, registrationNumbers} = this.props;

        return (
            <SectionContentWrapperV2 className="content-wrapper-related-info">
                <div className="related-info">
                    {!!registrationNumbers && this.renderRegistrationNumbers()}
                    {!!files && this.renderDocuments()}
                    {!!factsheets && this.renderRelatedFactsheets()}
                </div>
            </SectionContentWrapperV2>
        );
    }
}
