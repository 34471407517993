import React from 'react';

import {TELEHEALTH, TOOLTIP_TELEHEALTH_TEXT} from 'core/constants.js';
import {PillV2} from '@HealthShareAU/hs-component-library';

const TelehealthPill = ({colorTheme}) => {
    return (
        <PillV2
            customClass="telehealth"
            colorTheme={colorTheme}
            dataTestId="offers-telehealth-pill"
            iconFront="phonePlus"
            key="telehealth"
            text={TELEHEALTH}
            tooltip={TOOLTIP_TELEHEALTH_TEXT}
        />
    );
};
export default TelehealthPill;
