import React from 'react';
import {observer} from 'mobx-react';

import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import {QuestionsAndAnswersV2} from '@HealthShareAU/hs-component-library';
import {
    QUESTIONS_AND_ANSWERS_SECTION,
    QUESTIONS_AND_ANSWERS_SELECTOR,
} from 'core/constants';

export default
@observer
class QuestionsAndAnswersSection extends React.Component {
    static contextType = PracticeStoreContextV2;

    constructor(props) {
        super(props);
        this.state = {
            threadReplies: null,
        };
    }

    async getQuestionsAndAnswers() {
        const {userId} = this.context;
        try {
            const response = await fetch(
                `/api/accounts/v1/${userId}/thread-replies/`,
            );
            const data = await response.json();
            this.setState({threadReplies: data});
        } catch (error) {
            throw new Error('Failed to load thread replies');
        }
    }

    componentDidMount() {
        this.getQuestionsAndAnswers();
    }

    render() {
        const {
            practice: {avatar, name},
            totalThanks,
            totalContributions,
            totalAgrees,
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this.context;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;

        return !this.state.threadReplies ? null : (
            <>
                <QuestionsAndAnswersV2
                    colorTheme={colorTheme}
                    threadReplies={this.state.threadReplies}
                    totalCountInfo={{
                        agreedPractitioner: totalAgrees,
                        memberThanks: totalThanks,
                        totalQuestionAnswer: totalContributions,
                    }}
                    practitionerInfo={{image: avatar, name}}
                    dataTestId={QUESTIONS_AND_ANSWERS_SECTION}
                    gaObjectType={QUESTIONS_AND_ANSWERS_SECTION}
                    gaSelector={QUESTIONS_AND_ANSWERS_SELECTOR}
                />
            </>
        );
    }
}
