import React from 'react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';
import {CLAIM_YOUR_PROFILE,CLAIM_YOUR_PROFILE_TEXT,ABOUT_SECTION} from 'core/constants.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import {AboutSectionV2} from '@HealthShareAU/hs-component-library';

export default
@observer
class AboutSection extends React.Component {
    static contextType = PracticeStoreContextV2;
    constructor(props) {
        super(props);
    }

    render() {
        const practice = toJS(this.context.practice);
        const {
            bio,
            claimUrl,
            rootStore: {
                healthFundStore: {healthFundThemeColor},
                paramStore: {hasSkin},
            },
        } = this.context;

        const {
            description,
            id,
            specialInterests,
            awards,
            qualifications,
            name,
        } = practice;

        const header = `About ${name}`;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;

        let mainContent = {};
        const sideContent = [];

        let customUrl = {};

        mainContent = {
            text: bio || description,
            header,
        };

        if (claimUrl && !hasSkin) {
            customUrl = {
                gaObjectType:ABOUT_SECTION,
                gaSelector:CLAIM_YOUR_PROFILE,
                text: CLAIM_YOUR_PROFILE_TEXT,
                url: claimUrl
            };
        }

        if (id) {
            if (specialInterests) {
                sideContent.push({
                    'header': 'Special interests',
                    'text': specialInterests,
                });
            }

            if (qualifications) {
                sideContent.push({
                    'header': 'Qualifications',
                    'text': qualifications,
                });
            }
            if (awards) {
                sideContent.push({
                    'header': 'Awards and Publications',
                    'text': awards,
                });
            }
        }

        return (
            description && (
                <AboutSectionV2
                    customUrl={customUrl}
                    sideContent={sideContent}
                    mainContent={mainContent}
                    colorTheme={colorTheme}
                />
            )
        );
    }
}
