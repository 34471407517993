import React from 'react';
import {SEE_OUT_OF_POCKET_COSTS} from 'core/constants';
import {ChipV2, LinkV2} from '@HealthShareAU/hs-component-library';

const OutOfPocketCostsPill = ({gaObjectType, colorTheme}) => {
    return (
        <ChipV2
            allowSkin={!!colorTheme}
            colorTheme={colorTheme}
            customClass="fees"
            gaObjectType={gaObjectType}
            gaSelector={SEE_OUT_OF_POCKET_COSTS}
            icon="barChart"
        >
            <LinkV2
                colorTheme={colorTheme}
                href={`#fees`}
                text="See out-of-pocket costs"
                target="_self"
            />
        </ChipV2>
    );
};
export default OutOfPocketCostsPill;
